const GOOGLE_API_KEY = 'AIzaSyDkEiHce6_l9QzcbLttDkgN2_KxF9KWi_Q'

// Function used to generate a correct link path
// It's useful when different locales are used in URL
function getLinkPath (path, route) {
  let languages = ['pl', 'en']
  let urlParams = window.location.search
  if (typeof route.params.language !== 'undefined' && languages.includes(route.params.language)) {
    return '/' + route.params.language + '/' + path + urlParams
  } else {
    return '/' + path + urlParams
  }
}

function containsUppercase (str) {
  return /[A-Z]/.test(str)
}

function cleanSlug (slug) {
  // eslint-disable-next-line no-useless-escape
  return slug.replace(/[^a-zA-Z0-9-_]/g, '')
}

let Utils = {
  GOOGLE_API_KEY, getLinkPath, containsUppercase, cleanSlug
}

export default Utils
